<template>
  <el-tooltip
    :open-delay="700"
    :offset="2"
    :visible-arrow="false"
    effect="dark"
    :content="$t('content.object_stay')"
    enterable
    placement="bottom"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6338 11.3067H12.9969C13.8092 11.3067 14.4738 10.6827 14.4738 9.92001C14.4738 9.15734 13.8092 8.53334 12.9969 8.53334H10.6338V11.3067Z"
        fill="#4ABC96"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 12C0 5.3832 5.38321 0 12 0C18.6168 0 24.0001 5.3832 24.0001 12C24.0001 18.6168 18.6168 24 12 24C5.38321 24 0 18.6168 0 12ZM7.67999 5.76001H12.8492C15.2935 5.76001 17.28 7.62508 17.28 9.92001C17.28 12.2149 15.2935 14.08 12.8492 14.08H10.6338V18.24H7.67999V5.76001Z"
        fill="#4ABC96"
      />
    </svg>
  </el-tooltip>
</template>

<script>
export default {
  name: 'ParkingGreenIcon'
}
</script>
